import React, { useState } from 'react';
import {
  Container, Typography, TextField, Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Paper, Divider, Grid, Alert
} from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';

function RegisterPatientFollowUp() {
  const [registrationNo, setRegistrationNo] = useState('');
  const [registrationDetails, setRegistrationDetails] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const token = localStorage.getItem('token');

  const handleInputChange = (event) => {
    setRegistrationNo(event.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://satcdc.pune.ajwebapps.com/api/patient/${registrationNo}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setRegistrationDetails(response.data.patient);
      setError('');
    } catch (error) {
      setRegistrationDetails(null);
      setError('No registration found with that number');
    }
  };

  const handleConfirmationOpen = () => {
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleSubmit = async () => {
    handleConfirmationClose();
    try {
      const followUpData = {
        registration_no: registrationNo,
        date: format(new Date(), 'dd-MM-yyyy')
      };
      const response = await axios.post('https://satcdc.pune.ajwebapps.com/api/patient/followup', followUpData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSuccess(`Follow-up registered successfully with registration number: ${response.data.followup.registration_no}`);
      setRegistrationDetails(null);
      setRegistrationNo('');
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Follow-up registration failed');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Register Follow-Up
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              name="registrationNo"
              label="Registration No"
              variant="outlined"
              fullWidth
              value={registrationNo}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ height: '100%' }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        {registrationDetails && (
          <Paper sx={{ padding: 2, marginTop: 3 }}>
            <Typography variant="h6">Registration Details</Typography>
            <Divider sx={{ marginY: 1 }} />
            <Typography><strong>Name:</strong> {registrationDetails.title} {registrationDetails.name}</Typography>
            <Typography><strong>Age:</strong> {registrationDetails.age}</Typography>
            <Typography><strong>Sex:</strong> {registrationDetails.sex}</Typography>
            <Typography><strong>Date:</strong> {registrationDetails.date}</Typography>
            <Typography><strong>Valid Till:</strong> {registrationDetails.valid_till}</Typography>
            <Typography><strong>Department:</strong> {registrationDetails.department_name}</Typography>
            <Typography><strong>Doctor:</strong> Dr. {registrationDetails.doctor_name}</Typography>
            <Typography><strong>Type:</strong> {registrationDetails.type}</Typography>
            <Typography><strong>Fees:</strong> {registrationDetails.fees}</Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleConfirmationOpen}
            >
              Register Follow-Up
            </Button>
          </Paper>
        )}
      </Box>

      <Dialog open={confirmationOpen} onClose={handleConfirmationClose} maxWidth="sm" fullWidth>
        <DialogTitle>Confirm Follow-Up Registration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to register a follow-up for the patient with the following details?
          </DialogContentText>
          <Paper sx={{ padding: 2, marginTop: 2 }}>
            <Typography variant="h6">Follow-Up Details</Typography>
            <Divider sx={{ marginY: 1 }} />
            <Typography><strong>Name:</strong> {registrationDetails?.title} {registrationDetails?.name}</Typography>
            <Typography><strong>Age:</strong> {registrationDetails?.age}</Typography>
            <Typography><strong>Sex:</strong> {registrationDetails?.sex}</Typography>
            <Typography><strong>Department:</strong> {registrationDetails?.department_name}</Typography>
            <Typography><strong>Doctor:</strong> Dr. {registrationDetails?.doctor_name}</Typography>
            <Typography><strong>Date:</strong> {format(new Date(), 'dd-MM-yyyy')}</Typography>
            <Typography><strong>Valid Till:</strong> {registrationDetails?.valid_till}</Typography>
            <Typography><strong>Type:</strong> {registrationDetails?.type}</Typography>
            <Typography><strong>Fees:</strong> 0</Typography>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default RegisterPatientFollowUp;
