import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, TextField, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, InputAdornment, MenuItem, Select, FormControl, InputLabel, Grid
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';

function ManageDoctors() {
  const [doctors, setDoctors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [currentDoctor, setCurrentDoctor] = useState({ name: '', department_name: '', schedule: {} });
  const [action, setAction] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [validationError, setValidationError] = useState('');
  const [search, setSearch] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchDoctors();
    fetchDepartments();
  }, []);

  const fetchDoctors = async () => {
    try {
      const response = await axios.get('https://satcdc.pune.ajwebapps.com/api/doctor/all', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDoctors(response.data.data);
      setFilteredDoctors(response.data.data);
    } catch (error) {
      setError('Failed to fetch doctors');
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await axios.get('https://satcdc.pune.ajwebapps.com/api/department/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDepartments(response.data.data);
    } catch (error) {
      setError('Failed to fetch departments');
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    const filtered = doctors.filter(doctor => 
      doctor.name.toLowerCase().includes(value) || 
      doctor.department_name.toLowerCase().includes(value)
    );
    setFilteredDoctors(filtered);
  };

  const handleOpen = (action, doctor = { name: '', department_name: '', schedule: {} }) => {
    setAction(action);
    setCurrentDoctor(doctor);
    setOpen(true);
    setValidationError('');
    setError('');
    setSuccess('');
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
    setSuccess('');
    setValidationError('');
  };

  const handleConfirmationOpen = () => {
    if (!currentDoctor.name || !currentDoctor.department_name || Object.keys(currentDoctor.schedule).length === 0) {
      setValidationError('All fields are mandatory');
      return;
    }
    setValidationError('');
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleScheduleChange = (day, startTime, endTime) => {
    setCurrentDoctor({
      ...currentDoctor,
      schedule: {
        ...currentDoctor.schedule,
        [day]: `${startTime}-${endTime}`
      }
    });
  };

  const handleSubmit = async () => {
    handleConfirmationClose();
    try {
      if (action === 'Create') {
        await axios.post('https://satcdc.pune.ajwebapps.com/api/doctor/', currentDoctor, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('Doctor created successfully');
      } else if (action === 'Update') {
        await axios.put(`https://satcdc.pune.ajwebapps.com/api/doctor/${currentDoctor.id}`, currentDoctor, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('Doctor updated successfully');
      } else if (action === 'Delete') {
        await axios.delete(`https://satcdc.pune.ajwebapps.com/api/doctor/${currentDoctor.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('Doctor deleted successfully');
      }
      fetchDoctors();
      handleClose();
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Action failed');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Manage Doctors
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <Button variant="contained" color="primary" onClick={() => handleOpen('Create')}>
        Create Doctor
      </Button>
      <TextField
        placeholder="Search Doctors"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: 'primary.main', color: 'white' }}>
              <TableCell sx={{ color: 'white' }}>Doctor Name</TableCell>
              <TableCell sx={{ color: 'white' }}>Department</TableCell>
              <TableCell sx={{ color: 'white' }}>Schedule</TableCell>
              <TableCell sx={{ color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDoctors.map((doctor) => (
              <TableRow key={doctor.id}>
                <TableCell>{"Dr. " + doctor.name}</TableCell>
                <TableCell>{doctor.department_name}</TableCell>
                <TableCell>
                  {Object.entries(doctor.schedule).map(([day, time]) => (
                    <div key={day}>{day}: {time}</div>
                  ))}
                </TableCell>
                <TableCell>
                  <Button variant="outlined" color="primary" onClick={() => handleOpen('Update', doctor)}>
                    Update
                  </Button>
                  <Button variant="outlined" color="primary" onClick={() => handleOpen('Delete', doctor)} sx={{ ml: 1 }}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{action} Doctor</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the information below to {action.toLowerCase()} the doctor.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Doctor Name"
            type="text"
            fullWidth
            variant="outlined"
            value={currentDoctor.name}
            onChange={(e) => setCurrentDoctor({ ...currentDoctor, name: e.target.value })}
            required
            sx={{ mb: 3 }}
          />
          <FormControl fullWidth variant="outlined" margin="dense" sx={{ mb: 3 }}>
            <InputLabel id="department-label">Department</InputLabel>
            <Select
              labelId="department-label"
              id="department_name"
              value={currentDoctor.department_name}
              onChange={(e) => setCurrentDoctor({ ...currentDoctor, department_name: e.target.value })}
              label="Department"
              required
            >
              {departments.map((department) => (
                <MenuItem key={department.name} value={department.name}>
                  {department.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid container spacing={2}>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
              <Grid item xs={12} key={day}>
                <Typography variant="h6" component="h2" gutterBottom>
                  {day}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`${day}-start-label`}>Start Time</InputLabel>
                      <Select
                        labelId={`${day}-start-label`}
                        id={`${day}-start-time`}
                        value={currentDoctor.schedule[day]?.split('-')[0] || '12:00am'}
                        onChange={(e) => handleScheduleChange(day, e.target.value, currentDoctor.schedule[day]?.split('-')[1] || '12:00am')}
                        label="Start Time"
                      >
                        {Array.from({ length: 24 }).map((_, i) => {
                          const time = new Date(0, 0, 0, i).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true });
                          return <MenuItem key={time} value={time}>{time}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`${day}-end-label`}>End Time</InputLabel>
                      <Select
                        labelId={`${day}-end-label`}
                        id={`${day}-end-time`}
                        value={currentDoctor.schedule[day]?.split('-')[1] || '12:00am'}
                        onChange={(e) => handleScheduleChange(day, currentDoctor.schedule[day]?.split('-')[0] || '12:00am', e.target.value)}
                        label="End Time"
                      >
                        {Array.from({ length: 24 }).map((_, i) => {
                          const time = new Date(0, 0, 0, i).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true });
                          return <MenuItem key={time} value={time}>{time}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
          {validationError && <Alert severity="error" sx={{ mt: 2 }}>{validationError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleConfirmationOpen} color="primary">{action}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
        <DialogTitle>Confirm {action}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {action.toLowerCase()} this doctor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ManageDoctors;
