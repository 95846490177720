import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Grid, Alert, InputAdornment, IconButton, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { format } from 'date-fns';

function ListProcedureRegistration() {
  const [procedures, setProcedures] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredProcedures, setFilteredProcedures] = useState([]);
  const [error, setError] = useState('');
  const [department, setDepartment] = useState('ALL');
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchProcedures();
  }, [department]);

  const fetchProcedures = async () => {
    const today = format(new Date(), 'dd-MM-yyyy');
    try {
      const response = await axios.get('https://satcdc.pune.ajwebapps.com/api/procedure_registration/between_dates', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          start_date: today,
          end_date: today,
          department
        }
      });
      setProcedures(response.data.data);
      setFilteredProcedures(response.data.data);
    } catch (error) {
      setError('Failed to fetch procedures');
    }
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);

    const filtered = procedures.filter(proc =>
      proc.registration_no.toString().includes(value) ||
      proc.patient_name.toLowerCase().includes(value.toLowerCase()) ||
      proc.department_name.toLowerCase().includes(value.toLowerCase()) ||
      proc.referring_doctor.toLowerCase().includes(value.toLowerCase()) ||
      proc.total_amount.toString().includes(value)
    );
    setFilteredProcedures(filtered);
  };

  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Today's Procedures
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      <FormControl component="fieldset" sx={{ mb: 3 }}>
        <FormLabel component="legend">Department</FormLabel>
        <RadioGroup row value={department} onChange={handleDepartmentChange}>
          <FormControlLabel value="DENTAL" control={<Radio />} label="DENTAL" />
          <FormControlLabel value="PHYSIO THERAPY" control={<Radio />} label="PHYSIO THERAPY" />
          <FormControlLabel value="ALL" control={<Radio />} label="ALL" />
        </RadioGroup>
      </FormControl>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: 'primary.main', color: 'white' }}>
              <TableCell sx={{ color: 'white' }}>Procedure/Reg No</TableCell>
              <TableCell sx={{ color: 'white' }}>Procedure Date</TableCell>
              <TableCell sx={{ color: 'white' }}>Patient</TableCell>
              <TableCell sx={{ color: 'white' }}>Type</TableCell>
              <TableCell sx={{ color: 'white' }}>Department</TableCell>
              <TableCell sx={{ color: 'white' }}>Referred By</TableCell>
              <TableCell sx={{ color: 'white' }}>SAT Discount</TableCell>
              <TableCell sx={{ color: 'white' }}>Paid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProcedures.map((procedure) => (
              <TableRow key={procedure.id}>
                <TableCell>{procedure.id}/{procedure.registration_no}</TableCell>
                <TableCell>{procedure.procedure_date}</TableCell>
                <TableCell>{procedure.patient_name} {procedure.age}/{procedure.sex}</TableCell>
                <TableCell>{procedure.type}</TableCell>
                <TableCell>{procedure.department_name}</TableCell>
                <TableCell>{procedure.referring_doctor}</TableCell>
                <TableCell>{procedure.sat_discount}</TableCell>
                <TableCell>{procedure.total_amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default ListProcedureRegistration;
