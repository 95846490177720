import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');
  const location = useLocation();

  const isAdmin = userType === 'Admin';

  // Define routes that are restricted to Admin users only
  const adminRoutes = ['/manage-departments', '/manage-doctors', '/manage-users'];

  // Check if the current route is restricted and if the user is not an Admin
  if (adminRoutes.includes(location.pathname) && !isAdmin) {
    return <Navigate to="/home" />;
  }

  return token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
