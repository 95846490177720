import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import ManageDepartments from './pages/ManageDepartments';
import ManageDoctors from './pages/ManageDoctors';
import RegisterPatient from './pages/RegisterPatient';
import RefundPatientRegistration from './pages/RefundPatientRegistration';
import RegisterPatientFollowUp from './pages/RegisterPatientFollowUp';
import ListRegistrations from './pages/ListRegistrations';
import GenerateReportPatientReg from './pages/GenerateReportPatientReg';
import GenerateReportProcedureReg from './pages/GenerateReportProcedureRegs';
import ManageUsers from './pages/ManageUsers';
import ManageProcedures from './pages/ManageProcedures';
import RegisterProcedure from './pages/RegisterProcedure';
import ListProcedureRegistrations from './pages/ListProcedureRegistrations';
import RefundProcedure from './pages/RefundProcedure';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route element={<PrivateRoute />}>
            <Route element={<Layout />}>
              <Route path="/home" element={<HomePage />} />
              <Route path="/manage-departments" element={<ManageDepartments />} />
              <Route path="/manage-doctors" element={<ManageDoctors />} />
              <Route path="/register-patient" element={<RegisterPatient />} />
              <Route path="/register-patient-follow-up" element={<RegisterPatientFollowUp />} />
              <Route path="/list-registrations" element={<ListRegistrations />} />
              <Route path="/generate-reports-patient-regs" element={<GenerateReportPatientReg />} />
              <Route path="/generate-reports-procedure-regs" element={<GenerateReportProcedureReg />} />
              <Route path="/manage-users" element={<ManageUsers />} />
              <Route path="/manage-procedures" element={<ManageProcedures />} />
              <Route path="/register-procedure" element={<RegisterProcedure />} />
              <Route path="/list-procedure-registrations" element={<ListProcedureRegistrations />} />
              <Route path="/refund-procedure" element={<RefundProcedure />} />
              <Route path="/refund-patient-registration" element={<RefundPatientRegistration />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

