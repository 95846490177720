import React, { useState } from 'react';
import {
  Container, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Grid, Alert, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
  InputAdornment, IconButton, Box, Divider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function GenerateReportPatientReg() {
  const [reportType, setReportType] = useState('today');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [department, setDepartment] = useState('ALL');
  const [registrations, setRegistrations] = useState([]);
  const [filteredRegistrations, setFilteredRegistrations] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const token = localStorage.getItem('token');

  const formatDate = (date) => {
    return format(new Date(date), 'dd-MM-yyyy');
  };

  const endsWith = (str, suffix) => {
    return str.slice(-suffix.length) === suffix;
  };

  const fetchRegistrationsAndRefunds = async () => {
    let url = 'https://satcdc.pune.ajwebapps.com/api/patient/between_dates';
    let params = {};

    if (reportType === 'today') {
      const today = format(new Date(), 'dd-MM-yyyy');
      params = { start_date: today, end_date: today, department };
    } else {
      if (!startDate || !endDate) {
        setError('Please select both start date and end date.');
        return;
      }
      params = { start_date: formatDate(startDate), end_date: formatDate(endDate), department };
    }

    try {
      const [registrationResponse, refundResponse] = await Promise.all([
        axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params
        }),
        axios.get('https://satcdc.pune.ajwebapps.com/api/patient_refund/between_dates', {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params
        })
      ]);
      setRegistrations(registrationResponse.data.registrations);
      setFilteredRegistrations(registrationResponse.data.registrations);
      setRefunds(refundResponse.data.refunds);
      setError('');
    } catch (error) {
      setError('Failed to fetch registrations and refunds');
    }
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);

    const filtered = registrations.filter(reg =>
      reg.name.toLowerCase().includes(value.toLowerCase()) ||
      reg.department_name.toLowerCase().includes(value.toLowerCase()) ||
      reg.doctor_name.toLowerCase().includes(value.toLowerCase()) ||
      reg.registration_no.toString().includes(value)
    );
    setFilteredRegistrations(filtered);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredRegistrations);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Registrations');

    const headerStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: 'CFE8FC' } }
    };

    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_cell({ r: range.s.r, c: C });
      if (!worksheet[address]) continue;
      worksheet[address].s = headerStyle;
    }

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'registrations.xlsx');
  };

  
  const handleExportPDF = () => {
    const doc = new jsPDF();
    const date = formatDate(new Date());
  
    // Add header
    doc.setFontSize(12);
    doc.text(
      'Shri Anandpur Trust Charitable Medical Centre, Pune',
      doc.internal.pageSize.getWidth() / 2,
      10,
      { align: 'center' }
    );
  
    const reportDetails = [
      ['Report Interval:', reportType === 'today' ? `Today (${date})` : `${formatDate(startDate)} to ${formatDate(endDate)}`],
      ['Report Generated At:', new Date().toLocaleString()]
    ];
  
    // Add report details
    doc.autoTable({
      body: reportDetails,
      theme: 'grid',
      styles: { fontSize: 6, cellPadding: 0.1 }, // Increased padding for better clarity
      columnStyles: {
        0: { cellWidth: 50 }, // Adjust the width of columns
        1: { cellWidth: 100 }
      },
    });
  
    // Section heading for registrations
    doc.setFontSize(10);
  
    // Add registrations table
    doc.autoTable({
      theme: 'plain',
      head: [['Registrations']],
      styles: { fontSize: 8, cellPadding: 0.1 },
    });
  
    doc.autoTable({
      title: 'Registrations',
      theme: 'plain',
      head: [['Reg No', 'Name', 'Age / Sex', 'Date', 'Department', 'Type', 'Fees']],
      body: filteredRegistrations.map(reg => [
        reg.registration_no,
        reg.title + ' ' + reg.name,
        reg.age + '/' + reg.sex,
        reg.date,
        reg.department_name,
        reg.type,
        reg.fees,
      ]),
      styles: { fontSize: 6, cellPadding: 0.1 },
      columnStyles: {
        0: { cellWidth: 20 }, // Reg No
        1: { cellWidth: 40 }, // Name
        2: { cellWidth: 20 }, // Age / Sex
        3: { cellWidth: 30 }, // Date
        4: { cellWidth: 40 }, // Department
        5: { cellWidth: 20 }, // Type
        6: { cellWidth: 20 }, // Fees
      },
      didDrawCell: (data) => {
        // Wrap long text content inside cells
        const text = data.cell.text[0];
        const maxLength = 30; // Maximum characters per line
        if (text.length > maxLength) {
          data.cell.text = doc.splitTextToSize(text, data.cell.width - 4); // Wrapping text
        }
      }
    });
  
    if (refunds.length > 0) {
      if (doc.autoTable.previous.finalY > doc.internal.pageSize.getHeight() - 50) {
        doc.addPage();
      }
      // Section heading for refunds
      doc.setFontSize(10);
      doc.autoTable({
        theme: 'plain',
        head: [['Refunds']],
        styles: { fontSize: 8, cellPadding: 1 },
      });
      doc.autoTable({
        theme: 'plain',
        head: [['Reg No', 'Refund Amount', 'Refund Date']],
        body: refunds.map(refund => [
          refund.registration_no,
          refund.refund_amount,
          refund.refund_date,
        ]),
        styles: { fontSize: 6, cellPadding: 1 },
        columnStyles: {
          0: { cellWidth: 30 }, // Reg No
          1: { cellWidth: 50 }, // Refund Amount
          2: { cellWidth: 40 }, // Refund Date
        },
      });
    } else {
      if (doc.autoTable.previous.finalY > doc.internal.pageSize.getHeight() - 50) {
        doc.addPage();
      }
    }
  
    // Summary data
    const totalRegistrations = filteredRegistrations.length;
    const totalSatRegistrations = filteredRegistrations.filter(reg => reg.type === 'SAT').length;
    const totalNonSatRegistrations = filteredRegistrations.filter(reg => reg.type === 'Non-SAT').length;
    const newRegs = filteredRegistrations.filter(reg => !endsWith(reg.registration_no.toString(), 'F')).length;
    const newSatRegs = filteredRegistrations.filter(reg => !endsWith(reg.registration_no.toString(), 'F') && reg.type === 'SAT').length;
    const newNonSatRegs = filteredRegistrations.filter(reg => !endsWith(reg.registration_no.toString(), 'F') && reg.type === 'Non-SAT').length;
    const totalFollowUps = filteredRegistrations.filter(reg => endsWith(reg.registration_no.toString(), 'F')).length;
    const followUpSatRegs = filteredRegistrations.filter(reg => endsWith(reg.registration_no.toString(), 'F') && reg.type === 'SAT').length;
    const followUpNonSatRegs = filteredRegistrations.filter(reg => endsWith(reg.registration_no.toString(), 'F') && reg.type === 'Non-SAT').length;
    const totalCollection = filteredRegistrations.reduce((sum, reg) => sum + reg.fees, 0);
    const totalRefunds = refunds.reduce((sum, refund) => sum + refund.refund_amount, 0);
    const netCollection = totalCollection - totalRefunds;
  
    const summary = [
      `Total Registrations: ${totalRegistrations} (SAT: ${totalSatRegistrations}, Non-SAT: ${totalNonSatRegistrations})`,
      `New Registrations: ${newRegs} (SAT: ${newSatRegs}, Non-SAT: ${newNonSatRegs})`,
      `Total Follow Ups: ${totalFollowUps} (SAT: ${followUpSatRegs}, Non-SAT: ${followUpNonSatRegs})`,
      `Total Collection (INR): ${totalCollection}    Total Refunds (INR): ${totalRefunds}    Net Collection (INR): ${netCollection}`,
    ];
  
    // Add summary
    if (doc.autoTable.previous.finalY > doc.internal.pageSize.getHeight() - 50) {
      doc.addPage();
    }
    doc.setFontSize(10);
  
    // Add summary to the PDF
    doc.autoTable({
      theme: 'plain',
      head: [['Summary']],
      styles: { fontSize: 8, cellPadding: 1 },
    });
    doc.autoTable({
      body: summary.map(line => [line]),
      theme: 'grid',
      styles: { fontSize: 6, cellPadding: 1 },
    });
  
    doc.save('registrations.pdf');
  };
  

  const calculateSummary = () => {
    const totalRegistrations = filteredRegistrations.length;
    const totalSatRegistrations = filteredRegistrations.filter(reg => reg.type === 'SAT').length;
    const totalNonSatRegistrations = filteredRegistrations.filter(reg => reg.type === 'Non-SAT').length;
    const newRegs = filteredRegistrations.filter(reg => !endsWith(reg.registration_no.toString(), 'F')).length;
    const newSatRegs = filteredRegistrations.filter(reg => !endsWith(reg.registration_no.toString(), 'F') && reg.type === 'SAT').length;
    const newNonSatRegs = filteredRegistrations.filter(reg => !endsWith(reg.registration_no.toString(), 'F') && reg.type === 'Non-SAT').length;
    const totalFollowUps = filteredRegistrations.filter(reg => endsWith(reg.registration_no.toString(), 'F')).length;
    const followUpSatRegs = filteredRegistrations.filter(reg => endsWith(reg.registration_no.toString(), 'F') && reg.type === 'SAT').length;
    const followUpNonSatRegs = filteredRegistrations.filter(reg => endsWith(reg.registration_no.toString(), 'F') && reg.type === 'Non-SAT').length;
    const totalCollection = filteredRegistrations.reduce((sum, reg) => sum + reg.fees, 0);
    const totalRefunds = refunds.reduce((sum, refund) => sum + refund.refund_amount, 0);
    const netCollection = totalCollection - totalRefunds;

    return {
      totalRegistrations,
      totalSatRegistrations,
      totalNonSatRegistrations,
      newRegs,
      newSatRegs,
      newNonSatRegs,
      totalFollowUps,
      followUpSatRegs,
      followUpNonSatRegs,
      totalCollection,
      totalRefunds,
      netCollection
    };
  };

  const summary = calculateSummary();

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Generate Reports
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <FormControl component="fieldset" sx={{ mb: 4 }}>
        <FormLabel component="legend">Report Type</FormLabel>
        <RadioGroup row value={reportType} onChange={(e) => setReportType(e.target.value)}>
          <FormControlLabel value="today" control={<Radio />} label="Today" />
          <FormControlLabel value="dateRange" control={<Radio />} label="Date Range" />
        </RadioGroup>
      </FormControl>
      {reportType === 'dateRange' && (
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="End Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Grid>
        </Grid>
      )}
      
      <FormControl component="fieldset" sx={{ mb: 4 }}>
        <FormLabel component="legend">Department</FormLabel>
        <RadioGroup row value={department} onChange={(e) => setDepartment(e.target.value)}>
          <FormControlLabel value="DENTAL" control={<Radio />} label="DENTAL" />
          <FormControlLabel value="PHYSIO THERAPY" control={<Radio />} label="PHYSIO THERAPY" />
          <FormControlLabel value="POLY OPD" control={<Radio />} label="POLY OPD" />
          <FormControlLabel value="ALL" control={<Radio />} label="ALL" />
        </RadioGroup>
      </FormControl>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={fetchRegistrationsAndRefunds}>
            Generate Report
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={handlePrint}>
            Print
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success" onClick={handleExportExcel}>
            Save as Excel
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="error" onClick={handleExportPDF}>
            Save as PDF
          </Button>
        </Grid>
      </Grid>
      <TextField
        label="Search"

        variant="outlined"
        fullWidth
        value={search}
        onChange={handleSearchChange}
        sx={{ mb: 4 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {filteredRegistrations.length > 0 && (
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#cfe8fc' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Reg No</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Age/Sex</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Department</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Type</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Fees</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRegistrations.map((registration) => (
                <TableRow key={registration.registration_no}>
                  <TableCell sx={{ padding: '4px' }}>{registration.registration_no}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{registration.title} {registration.name}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{registration.age} / {registration.sex}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{registration.date}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{registration.department_name}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{registration.type}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{registration.fees}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {refunds.length > 0 && (
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Refunds
          </Typography>
          <Table>
            <TableHead sx={{ backgroundColor: '#ffcdd2' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: '#b71c1c', padding: '4px' }}>Reg No</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#b71c1c', padding: '4px' }}>Refund Amount</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#b71c1c', padding: '4px' }}>Refund Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refunds.map((refund) => (
                <TableRow key={refund.registration_no}>
                  <TableCell sx={{ padding: '4px' }}>{refund.registration_no}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{refund.refund_amount}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{refund.refund_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Divider sx={{ mb: 2 }} />
      <Typography variant="h6" sx={{ mb: 2 }}>
        Summary
      </Typography>
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Total Registrations:</strong> {summary.totalRegistrations} (SAT: {summary.totalSatRegistrations}, Non-SAT: {summary.totalNonSatRegistrations})</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>New Registrations:</strong> {summary.newRegs} (SAT: {summary.newSatRegs}, Non-SAT: {summary.newNonSatRegs})</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Total Follow Ups:</strong> {summary.totalFollowUps} (SAT: {summary.followUpSatRegs}, Non-SAT: {summary.followUpNonSatRegs})</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Total Collection (INR):</strong> {summary.totalCollection}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Total Refunds (INR):</strong> {summary.totalRefunds}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Net Collection (INR):</strong> {summary.netCollection}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default GenerateReportPatientReg;
