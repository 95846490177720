import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, TextField, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, InputAdornment, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';

function ManageProcedures() {
  const [procedures, setProcedures] = useState([]);
  const [filteredProcedures, setFilteredProcedures] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [currentProcedure, setCurrentProcedure] = useState({ procedure_name: '', department_name: '', fees_per_sitting: '' });
  const [action, setAction] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [validationError, setValidationError] = useState('');
  const [search, setSearch] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchProcedures();
  }, []);

  const fetchProcedures = async () => {
    try {
      const response = await axios.get('https://satcdc.pune.ajwebapps.com/api/procedure/all', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setProcedures(response.data.data);
      setFilteredProcedures(response.data.data);
    } catch (error) {
      setError('Failed to fetch procedures');
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    const filtered = procedures.filter(procedure => 
      procedure.procedure_name.toLowerCase().includes(value) || 
      procedure.department_name.toLowerCase().includes(value)
    );
    setFilteredProcedures(filtered);
  };

  const handleOpen = (action, procedure = { procedure_name: '', department_name: '', fees_per_sitting: '' }) => {
    setAction(action);
    setCurrentProcedure(procedure);
    setOpen(true);
    setValidationError('');
    setError('');
    setSuccess('');
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
    setSuccess('');
    setValidationError('');
  };

  const handleConfirmationOpen = () => {
    if (!currentProcedure.procedure_name || !currentProcedure.department_name || !currentProcedure.fees_per_sitting) {
      setValidationError('All fields are mandatory');
      return;
    }
    setValidationError('');
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleSubmit = async () => {
    handleConfirmationClose();
    try {
      if (action === 'Create') {
        await axios.post('https://satcdc.pune.ajwebapps.com/api/procedure/', currentProcedure, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('Procedure created successfully');
      } else if (action === 'Update') {
        await axios.put(`https://satcdc.pune.ajwebapps.com/api/procedure/${currentProcedure.id}`, currentProcedure, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('Procedure updated successfully');
      } else if (action === 'Delete') {
        await axios.delete(`https://satcdc.pune.ajwebapps.com/api/procedure/${currentProcedure.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('Procedure deleted successfully');
      }
      fetchProcedures();
      handleClose();
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Action failed');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Manage Procedures
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <Button variant="contained" color="primary" onClick={() => handleOpen('Create')}>
        Create Procedure
      </Button>
      <TextField
        placeholder="Search Procedures"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: 'primary.main', color: 'white' }}>
              <TableCell sx={{ color: 'white' }}>Procedure Name</TableCell>
              <TableCell sx={{ color: 'white' }}>Department Name</TableCell>
              <TableCell sx={{ color: 'white' }}>Fees Per Sitting (INR)</TableCell>
              <TableCell sx={{ color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProcedures.map((procedure) => (
              <TableRow key={procedure.id}>
                <TableCell>{procedure.procedure_name}</TableCell>
                <TableCell>{procedure.department_name}</TableCell>
                <TableCell>{procedure.fees_per_sitting}</TableCell>
                <TableCell>
                  <Button variant="outlined" color="primary" onClick={() => handleOpen('Update', procedure)}>
                    Update
                  </Button>
                  <Button variant="outlined" color="primary" onClick={() => handleOpen('Delete', procedure)} sx={{ ml: 1 }}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{action} Procedure</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the information below to {action.toLowerCase()} the procedure.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="procedure_name"
            label="Procedure Name"
            type="text"
            fullWidth
            variant="outlined"
            value={currentProcedure.procedure_name}
            onChange={(e) => setCurrentProcedure({ ...currentProcedure, procedure_name: e.target.value })}
            disabled={action !== 'Create'}
            required
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="department-label">Department Name</InputLabel>
            <Select
              labelId="department-label"
              id="department_name"
              value={currentProcedure.department_name}
              onChange={(e) => setCurrentProcedure({ ...currentProcedure, department_name: e.target.value })}
              label="Department Name"
              required
            >
              <MenuItem value="DENTAL">DENTAL</MenuItem>
              <MenuItem value="PHYSIO THERAPY">PHYSIO THERAPY</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            id="fees_per_sitting"
            label="Fees Per Sitting (INR)"
            type="number"
            fullWidth
            variant="outlined"
            value={currentProcedure.fees_per_sitting}
            onChange={(e) => setCurrentProcedure({ ...currentProcedure, fees_per_sitting: e.target.value })}
            required
          />
          {validationError && <Alert severity="error">{validationError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleConfirmationOpen} color="primary">{action}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
        <DialogTitle>Confirm {action}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {action.toLowerCase()} this procedure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ManageProcedures;
