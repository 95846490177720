import React, { useState } from 'react';
import {
  Container, Typography, TextField, Button, Grid, Alert, Paper, Divider, Box
} from '@mui/material';
import axios from 'axios';

function RefundPatientRegistration() {
  const [registrationNo, setRegistrationNo] = useState('');
  const [refundAmount, setRefundAmount] = useState('');
  const [patientDetails, setPatientDetails] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const token = localStorage.getItem('token');

  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://satcdc.pune.ajwebapps.com/api/patient/${registrationNo}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPatientDetails(response.data.patient);
      setError('');
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Patient not found');
      setPatientDetails(null);
    }
  };

  const handleRefund = async () => {
    if (refundAmount > patientDetails.fees) {
      setError('Refund amount cannot be greater than the fees paid');
      return;
    }

    if (patientDetails.type === 'SAT' && patientDetails.fees === 0) {
      setError('Refund is not applicable for SAT type if fees paid is 0');
      return;
    }

    try {
      const response = await axios.post('https://satcdc.pune.ajwebapps.com/api/patient_refund/refund', {
        registration_no: registrationNo,
        refund_amount: refundAmount
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSuccess('Refund processed successfully');
      setError('');
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Refund failed');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Refund Patient Registration
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={8}>
          <TextField
            label="Registration No"
            variant="outlined"
            fullWidth
            value={registrationNo}
            onChange={(e) => setRegistrationNo(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 1 }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      {patientDetails && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">Patient Details</Typography>
          <Divider sx={{ my: 1 }} />
          <Box>
            <Typography><strong>Name:</strong> {patientDetails.title} {patientDetails.name}</Typography>
            <Typography><strong>Age:</strong> {patientDetails.age}</Typography>
            <Typography><strong>Sex:</strong> {patientDetails.sex}</Typography>
            <Typography><strong>Department:</strong> {patientDetails.department_name}</Typography>
            <Typography><strong>Doctor:</strong> {patientDetails.doctor_name}</Typography>
            <Typography><strong>Type:</strong> {patientDetails.type}</Typography>
            <Typography><strong>Fees Paid:</strong> {patientDetails.fees}</Typography>
          </Box>
          <TextField
            label="Refund Amount"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={refundAmount}
            onChange={(e) => setRefundAmount(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleRefund}
          >
            Process Refund
          </Button>
        </Paper>
      )}
    </Container>
  );
}

export default RefundPatientRegistration;
