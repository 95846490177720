import React, { useState } from 'react';
import {
  Container, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Grid, Alert, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
  InputAdornment, IconButton, Box, Divider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function GenerateReportProcedureReg() {
  const [reportType, setReportType] = useState('today');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [department, setDepartment] = useState('ALL');
  const [procedures, setProcedures] = useState([]);
  const [filteredProcedures, setFilteredProcedures] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const token = localStorage.getItem('token');

  const formatDate = (date) => {
    return format(new Date(date), 'dd-MM-yyyy');
  };

  const fetchProceduresAndRefunds = async () => {
    let url = 'https://satcdc.pune.ajwebapps.com/api/procedure_registration/between_dates';
    let params = {};

    if (reportType === 'today') {
      const today = format(new Date(), 'dd-MM-yyyy');
      params = { start_date: today, end_date: today, department };
    } else {
      if (!startDate || !endDate) {
        setError('Please select both start date and end date.');
        return;
      }
      params = { start_date: formatDate(startDate), end_date: formatDate(endDate), department };
    }

    try {
      const [procedureResponse, refundResponse] = await Promise.all([
        axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params
        }),
        axios.get('https://satcdc.pune.ajwebapps.com/api/procedure_refund/between_dates', {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params
        })
      ]);
      setProcedures(procedureResponse.data.data || []);
      setFilteredProcedures(procedureResponse.data.data || []);
      setRefunds(refundResponse.data.refunds || []);
      setError('');
    } catch (error) {
      setError('Failed to fetch procedures and refunds');
    }
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);

    const filtered = (procedures || []).filter(proc =>
      proc.patient_name.toLowerCase().includes(value.toLowerCase()) ||
      proc.department_name.toLowerCase().includes(value.toLowerCase()) ||
      proc.referring_doctor.toLowerCase().includes(value.toLowerCase()) ||
      proc.registration_no.toString().includes(value)
    );
    setFilteredProcedures(filtered);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredProcedures);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Procedures');

    const headerStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: 'CFE8FC' } }
    };

    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_cell({ r: range.s.r, c: C });
      if (!worksheet[address]) continue;
      worksheet[address].s = headerStyle;
    }

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'procedures.xlsx');
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const date = formatDate(new Date());

    // Add header
    doc.setFontSize(12);
    doc.text('Shri Anandpur Trust Charitable Medical Centre, Pune', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

    const reportDetails = [
      ['Report Interval:', reportType === 'today' ? `Today (${date})` : `${formatDate(startDate)} to ${formatDate(endDate)}`],
      ['Report Generated At:', new Date().toLocaleString()]
    ];

    // Add report details
    doc.autoTable({
      body: reportDetails,
      theme: 'grid',
      styles: { fontSize: 6, cellPadding: 0}
    });

    // Add registrations table
    doc.autoTable({
        theme: 'plain',
        head: [['Procedures']],
        styles: { fontSize: 8, cellPadding: 0 }
        
    });

    // Add procedures table
    doc.autoTable({
      theme: 'plain',
      head: [['Proc / Reg No', 'Patient Name', 'Procedure Date', 'Department', 'Referring Doctor', 'Total Amount']],
      body: (filteredProcedures || []).map(proc => [
        proc.id + '/' + proc.registration_no,
        proc.patient_name,
        proc.procedure_date,
        proc.department_name,
        proc.referring_doctor,
        proc.total_amount
      ]),
      styles: { fontSize: 6, cellPadding: 0},
      margin: { top: 10 }
    });

    if (refunds && refunds.length > 0) {
      if (doc.autoTable.previous.finalY > doc.internal.pageSize.getHeight() - 50) {
        doc.addPage();
      }
      // Section heading for refunds
      doc.autoTable({
        theme: 'plain',
        head: [['Refunds']],
        styles: { fontSize: 8, cellPadding: 0 }
      });
      doc.autoTable({
        theme: 'plain',
        head: [['Proc / Reg No', 'Refund Amount', 'Refund Date']],
        body: refunds.map(refund => [
          refund.id + '/' + refund.procedure_registration_id,
          refund.refund_amount,
          refund.refund_date
        ]),
        styles: { fontSize: 6, cellPadding: 0},
        margin: { top: 10 }
      });
    } else {
      if (doc.autoTable.previous.finalY > doc.internal.pageSize.getHeight() - 50) {
        doc.addPage();
      }
      // Section heading for refunds
      doc.setFontSize(10);
      doc.text('Refunds: NONE', 14, doc.autoTable.previous.finalY + 10);
    }

    // Summary data
    const totalProcedures = filteredProcedures ? filteredProcedures.length : 0;
    const totalSatProcedures = filteredProcedures ? filteredProcedures.filter(proc => proc.type === 'SAT').length : 0;
    const totalNonSatProcedures = filteredProcedures ? filteredProcedures.filter(proc => proc.type === 'Non-SAT').length : 0;
    const totalSatDiscount = filteredProcedures ? filteredProcedures.reduce((sum, proc) => sum + proc.sat_discount, 0) : 0;
    const totalNonSatPayments = filteredProcedures ? filteredProcedures.reduce((sum, proc) => sum + (proc.type === 'Non-SAT' ? proc.total_amount : 0), 0) : 0;
    const totalRefunds = refunds ? refunds.reduce((sum, refund) => sum + refund.refund_amount, 0) : 0;
    const netCollection = totalNonSatPayments - totalRefunds;

    const summary = [
      `Total Procedures: ${totalProcedures} (SAT: ${totalSatProcedures}, Non-SAT: ${totalNonSatProcedures})`,
      `SAT Discount: ${totalSatDiscount}`,
      `Non-SAT Payments: ${totalNonSatPayments}    Refunds: ${totalRefunds}    Total: ${netCollection}`
    ];

    // Add summary
    if (doc.autoTable.previous.finalY > doc.internal.pageSize.getHeight() - 50) {
      doc.addPage();
    }

    // Add summary to the PDF
    doc.autoTable({
        theme: 'plain',
        head: [['Summary']],
        styles: { fontSize: 8, cellPadding: 0 },
        margin: { top: 0, bottom: 0 }
      });
  
    doc.autoTable({
      body: summary.map(line => [line]),
      theme: 'grid',
      styles: { fontSize: 6, cellPadding: 1 },
      margin: { top: 0, bottom: 0 }
    });

    doc.save('procedures.pdf');
  };

  const calculateSummary = () => {
    const totalProcedures = filteredProcedures ? filteredProcedures.length : 0;
    const totalSatProcedures = filteredProcedures ? filteredProcedures.filter(proc => proc.type === 'SAT').length : 0;
    const totalNonSatProcedures = filteredProcedures ? filteredProcedures.filter(proc => proc.type === 'Non-SAT').length : 0;
    const totalSatDiscount = filteredProcedures ? filteredProcedures.reduce((sum, proc) => sum + proc.sat_discount, 0) : 0;
    const totalNonSatPayments = filteredProcedures ? filteredProcedures.reduce((sum, proc) => sum + (proc.type === 'Non-SAT' ? proc.total_amount : 0), 0) : 0;
    const totalRefunds = refunds ? refunds.reduce((sum, refund) => sum + refund.refund_amount, 0) : 0;
    const netCollection = totalNonSatPayments - totalRefunds;

    return {
      totalProcedures,
      totalSatProcedures,
      totalNonSatProcedures,
      totalSatDiscount,
      totalNonSatPayments,
      totalRefunds,
      netCollection
    };
  };

  const summary = calculateSummary();

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Generate Reports
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <FormControl component="fieldset" sx={{ mb: 4 }}>
        <FormLabel component="legend">Report Type</FormLabel>
        <RadioGroup row value={reportType} onChange={(e) => setReportType(e.target.value)}>
          <FormControlLabel value="today" control={<Radio />} label="Today" />
          <FormControlLabel value="dateRange" control={<Radio />} label="Date Range" />
        </RadioGroup>
      </FormControl>
      {reportType === 'dateRange' && (
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="End Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Grid>
        </Grid>
      )}
      <FormControl component="fieldset" sx={{ mb: 4 }}>
        <FormLabel component="legend">Department</FormLabel>
        <RadioGroup row value={department} onChange={(e) => setDepartment(e.target.value)}>
          <FormControlLabel value="DENTAL" control={<Radio />} label="DENTAL" />
          <FormControlLabel value="PHYSIO THERAPY" control={<Radio />} label="PHYSIO THERAPY" />
          <FormControlLabel value="ALL" control={<Radio />} label="ALL" />
        </RadioGroup>
      </FormControl>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={fetchProceduresAndRefunds}>
            Generate Report
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={handlePrint}>
            Print
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success" onClick={handleExportExcel}>
            Save as Excel
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="error" onClick={handleExportPDF}>
            Save as PDF
          </Button>
        </Grid>
      </Grid>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={search}
        onChange={handleSearchChange}
        sx={{ mb: 4 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {filteredProcedures && filteredProcedures.length > 0 && (
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#cfe8fc' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Proc / Reg No</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Patient Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Procedure Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Department</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Referring Doctor</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#004d40', padding: '4px' }}>Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProcedures.map((procedure) => (
                <TableRow key={procedure.id}>
                  <TableCell sx={{ padding: '4px' }}>{procedure.id}/{procedure.registration_no}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{procedure.patient_name}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{procedure.procedure_date}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{procedure.department_name}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{procedure.referring_doctor}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{procedure.total_amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {refunds && refunds.length > 0 && (
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Refunds
          </Typography>
          <Table>
            <TableHead sx={{ backgroundColor: '#ffcdd2' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: '#b71c1c', padding: '4px' }}>Proc / Reg No</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#b71c1c', padding: '4px' }}>Refund Amount</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#b71c1c', padding: '4px' }}>Refund Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refunds.map((refund) => (
                <TableRow key={refund.registration_no}>
                  <TableCell sx={{ padding: '4px' }}>{refund.id}/{refund.procedure_registration_id}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{refund.refund_amount}</TableCell>
                  <TableCell sx={{ padding: '4px' }}>{refund.refund_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Divider sx={{ mb: 2 }} />
      <Typography variant="h6" sx={{ mb: 2 }}>
        Summary
      </Typography>
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Total Procedures:</strong> {summary.totalProcedures} (SAT: {summary.totalSatProcedures}, Non-SAT: {summary.totalNonSatProcedures})</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>SAT Discount:</strong> {summary.totalSatDiscount}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Non-SAT Payments:</strong> {summary.totalNonSatPayments}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Total Refunds (INR):</strong> {summary.totalRefunds}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Net Collection (INR):</strong> {summary.netCollection}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default GenerateReportProcedureReg;
