import React, { useState, useRef, useEffect } from 'react';
import {
  Container, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Box, Radio, RadioGroup, FormControlLabel,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Alert, Paper, Divider, Table, TableHead, TableBody, TableRow, TableCell
} from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import { useReactToPrint } from 'react-to-print';

function RegisterPatient() {
  const [departments, setDepartments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    name: '',
    age: '',
    sex: '',
    date: format(new Date(), 'dd-MM-yyyy'),
    valid_till: format(new Date(Date.now() + 6 * 24 * 60 * 60 * 1000), 'dd-MM-yyyy'), // 7 days including today
    department_name: '',
    doctor_id: '',
    type: 'Non-SAT',
    fees: '20',
    new_registration: true,
  });
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [registeredPatient, setRegisteredPatient] = useState(null);
  const token = localStorage.getItem('token');
  const receiptRef = useRef();

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get('https://satcdc.pune.ajwebapps.com/api/department/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDepartments(response.data.data);
    } catch (error) {
      setError('Failed to fetch departments');
    }
  };

  const fetchDoctors = async (department_name) => {
    try {
      const response = await axios.get(`https://satcdc.pune.ajwebapps.com/api/doctor/${department_name}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDoctors(response.data.data);
    } catch (error) {
      setError('Failed to fetch doctors');
    }
  };

  const handleDepartmentChange = (event) => {
    const department_name = event.target.value;
    setFormData(prevState => ({
      ...prevState,
      department_name: department_name,
      fees: department_name === 'PHYSIO THERAPY' ? '0' : '20'
    }));
    fetchDoctors(department_name);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'name' ? value.toUpperCase() : value
    }));
  };

  const handleTypeChange = (event) => {
    const type = event.target.value;
    const fees = type === 'SAT' ? '0' : '20';
    setFormData(prevState => ({
      ...prevState,
      type: type,
      fees: prevState.department_name === 'PHYSIO THERAPY' ? '0' : fees
    }));
  };

  const handleConfirmationOpen = () => {
    // Validation
    if (!formData.title || !formData.name || !formData.age || !formData.sex || !formData.department_name || !formData.doctor_id || (!formData.fees && formData.department_name !== 'PHYSIO THERAPY')) {
      setError('All fields are mandatory');
      return;
    }

    const age = parseInt(formData.age);
    if (isNaN(age) || age < 0 || age > 120) {
      setError('Please enter a valid age between 0 and 120');
      return;
    }

    const fees = parseInt(formData.fees);
    if (isNaN(fees)) {
      setError('Please enter a valid fee amount');
      return;
    }

    setError('');
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleSubmit = async () => {
    handleConfirmationClose();
    try {
      const response = await axios.post('https://satcdc.pune.ajwebapps.com/api/patient/', formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const message = formData.department_name === 'PHYSIO THERAPY' 
        ? `Patient registered successfully with registration number: ${response.data.patient.registration_no}.`
        : `Patient registered successfully with registration number: ${response.data.patient.registration_no}`;
      setSuccess(message);
      setRegisteredPatient(formData.department_name === 'PHYSIO THERAPY' ? null : response.data.patient);
      setFormData({
        title: '',
        name: '',
        age: '',
        sex: '',
        date: format(new Date(), 'dd-MM-yyyy'),
        valid_till: format(new Date(Date.now() + 6 * 24 * 60 * 60 * 1000), 'dd-MM-yyyy'),
        department_name: '',
        doctor_id: '',
        type: 'Non-SAT',
        fees: '20',
        new_registration: true,
      });
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Registration failed');
    }
  };

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Register Patient
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <Box component="form" noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="title-label">Title</InputLabel>
              <Select
                labelId="title-label"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                label="Title"
              >
                <MenuItem value="Mr">Mr</MenuItem>
                <MenuItem value="Ms">Ms</MenuItem>
                <MenuItem value="Mrs">Mrs</MenuItem>
                <MenuItem value="Miss">Miss</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              required
              value={formData.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="age"
              label="Age"
              variant="outlined"
              fullWidth
              required
              value={formData.age}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="sex-label">Sex</InputLabel>
              <Select
                labelId="sex-label"
                id="sex"
                name="sex"
                value={formData.sex}
                onChange={handleInputChange}
                label="Sex"
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="date"
              label="Date"
              variant="outlined"
              fullWidth
              required
              value={formData.date}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="valid_till"
              label="Valid Till"
              variant="outlined"
              fullWidth
              required
              value={formData.valid_till}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="department-label">Department</InputLabel>
              <Select
                labelId="department-label"
                id="department_name"
                name="department_name"
                value={formData.department_name}
                onChange={handleDepartmentChange}
                label="Department"
              >
                {departments.map((department) => (
                  <MenuItem key={department.name} value={department.name}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="doctor-label">Doctor</InputLabel>
              <Select
                labelId="doctor-label"
                id="doctor_id"
                name="doctor_id"
                value={formData.doctor_id}
                onChange={handleInputChange}
                label="Doctor"
              >
                {doctors.map((doctor) => (
                  <MenuItem key={doctor.id} value={doctor.id}>
                    Dr. {doctor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" required>
              <RadioGroup row name="type" value={formData.type} onChange={handleTypeChange}>
                <FormControlLabel value="Non-SAT" control={<Radio />} label="Non-SAT" />
                <FormControlLabel value="SAT" control={<Radio />} label="SAT" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {formData.department_name !== 'PHYSIO THERAPY' && (
              <TextField
                name="fees"
                label="Fees (INR)"
                variant="outlined"
                fullWidth
                required
                value={formData.fees}
                onChange={handleInputChange}
                InputProps={{ readOnly: true }}
              />
            )}
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleConfirmationOpen}
        >
          Register
        </Button>
      </Box>

      <Dialog open={confirmationOpen} onClose={handleConfirmationClose} maxWidth="md" fullWidth>
        <DialogTitle>Confirm Registration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to register the patient with the following details?
          </DialogContentText>
          <Paper sx={{ padding: 2, marginTop: 2 }}>
            <Typography variant="h6">Patient Details</Typography>
            <Divider sx={{ marginY: 1 }} />
            <Typography><strong>Title:</strong> {formData.title}</Typography>
            <Typography><strong>Name:</strong> {formData.name}</Typography>
            <Typography><strong>Age:</strong> {formData.age}</Typography>
            <Typography><strong>Sex:</strong> {formData.sex}</Typography>
            <Typography><strong>Date:</strong> {formData.date}</Typography>
            <Typography><strong>Valid Till:</strong> {formData.valid_till}</Typography>
            <Typography><strong>Department:</strong> {formData.department_name}</Typography>
            <Typography><strong>Doctor:</strong> Dr. {doctors.find(doc => doc.id === formData.doctor_id)?.name}</Typography>
            <Typography><strong>Type:</strong> {formData.type}</Typography>
            {formData.department_name !== 'PHYSIO THERAPY' && <Typography><strong>Fees:</strong> {formData.fees}</Typography>}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>

      {registeredPatient && (
        <Dialog open={!!registeredPatient} onClose={() => setRegisteredPatient(null)} maxWidth="md" fullWidth>
          <DialogContent>
            <Box ref={receiptRef} sx={{ padding: 6, color: '#fc0373', height: '100%', width: '100%' }}>
              <Grid container spacing={1} sx={{ fontSize: '0.6rem', marginTop: '18%' }}>
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Reg No:</strong> {registeredPatient.registration_no}</Typography>
                  </Box>
                </Grid>
               
               {/* 
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Title:</strong> {registeredPatient.title}</Typography>
                  </Box>
                </Grid>
                */}
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Name:</strong> {registeredPatient.title} {registeredPatient.name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Age:</strong> {registeredPatient.age}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Sex:</strong> {registeredPatient.sex}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Date:</strong> {registeredPatient.date}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Valid Till:</strong> {registeredPatient.valid_till}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Dept:</strong> {registeredPatient.department_name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Doctor:</strong> {registeredPatient.doctor_name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Type:</strong> {registeredPatient.type}</Typography>
                  </Box>
                </Grid>
                {registeredPatient.department_name !== 'PHYSIO THERAPY' && (
                  <Grid item xs={6}>
                    <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                      <Typography sx={{ fontSize: '0.7rem' }}><strong>Fees:</strong> {registeredPatient.fees}</Typography>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Box sx={{ border: '0.2px solid #fc0373', padding: 0.1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem' }}><strong>Cashier:</strong> </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Table sx={{ tableLayout: 'fixed', border: '0.2px solid #fc0373', height: '60%', marginTop: '2%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '20%', border: '0.2px solid #fc0373', color: '#fc0373', fontSize: '0.5rem' }}>Date</TableCell>
                    <TableCell style={{ width: '80%', border: '0.2px solid #fc0373', color: '#fc0373', fontSize: '0.5rem' }}>Prescription</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ height: 250, border: '0.2px solid #fc0373' }} />
                    <TableCell style={{ border: '0.2px solid #fc0373' }} />
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRegisteredPatient(null)} color="secondary">Close</Button>
            {registeredPatient.department_name !== 'PHYSIO THERAPY' && (
              <Button onClick={handlePrint} color="primary">Print Receipt</Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
}

export default RegisterPatient;
