import React, { useState, useEffect } from 'react';
import {
  AppBar, Toolbar, Typography, IconButton, Button, Box, CssBaseline, Container, Menu, MenuItem,
  Drawer, List, ListItem, ListItemText, useTheme, useMediaQuery, Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet, useNavigate } from 'react-router-dom';
import MoreIcon from '@mui/icons-material/MoreVert';

function Layout() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [proceduresAnchorEl, setProceduresAnchorEl] = useState(null);
  const [reportsAnchorEl, setReportsAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userType, setUserType] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    navigate('/');
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleCloseMenu();
    handleCloseProceduresMenu();
    handleMobileMenuClose();
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenProceduresMenu = (event) => {
    setProceduresAnchorEl(event.currentTarget);
  };

  const handleOpenReportsMenu = (event) => {
    setReportsAnchorEl(event.currentTarget);
  };

  const handleCloseProceduresMenu = () => {
    setProceduresAnchorEl(null);
  };

  const handleCloseReportsMenu = () => {
    setReportsAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const drawer = (
    <Box sx={{ width: 250 }}>
      <List>
        <ListItem button onClick={() => handleNavigation('/home')}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={handleOpenMenu}>
          <ListItemText primary="Manage Patient Registrations" />
        </ListItem>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
          <MenuItem onClick={() => handleNavigation('/register-patient')}>Register Patient</MenuItem>
          <MenuItem onClick={() => handleNavigation('/register-patient-follow-up')}>Register Patient Follow Up</MenuItem>
          <MenuItem onClick={() => handleNavigation('/list-registrations')}>List Registrations</MenuItem>
        </Menu>

        <ListItem button onClick={handleOpenReportsMenu}>
          <ListItemText primary="Generate Report" />
        </ListItem>
        <Menu anchorEl={reportsAnchorEl} open={Boolean(reportsAnchorEl)} onClose={handleCloseReportsMenu}>
          <MenuItem onClick={() => handleNavigation('/generate-reports-patient-regs')}>Patient Registration</MenuItem>
          <MenuItem onClick={() => handleNavigation('/generate-reports-procedure-regs')}>Procedure</MenuItem>
        </Menu>
        
        <ListItem button onClick={handleOpenProceduresMenu}>
          <ListItemText primary="Procedures" />
        </ListItem>
        <Menu anchorEl={proceduresAnchorEl} open={Boolean(proceduresAnchorEl)} onClose={handleCloseProceduresMenu}>
          <MenuItem onClick={() => handleNavigation('/register-procedure')}>Register Procedure</MenuItem>
          <MenuItem onClick={() => handleNavigation('/list-procedure-registrations')}>List Procedure Registrations</MenuItem>
        </Menu>
        {userType === 'Admin' && (
          <>
            <ListItem button onClick={() => handleNavigation('/manage-users')}>
              <ListItemText primary="Manage Users" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/manage-departments')}>
              <ListItemText primary="Manage Departments" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/manage-doctors')}>
              <ListItemText primary="Manage Doctors" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/manage-procedures')}>
              <ListItemText primary="Manage Procedures" />
            </ListItem>
          </>
        )}
        <ListItem button onClick={handleLogout}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar position="static" color="primary">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              SATCDC - Pune
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
            <Button color="inherit" onClick={() => handleNavigation('/home')} sx={{ mx: 1 }}>
              Home
            </Button>
            <Button color="inherit" onClick={handleOpenMenu} sx={{ mx: 1 }}>
              Manage Patient Registrations
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
              <MenuItem onClick={() => handleNavigation('/register-patient')}>Register Patient</MenuItem>
              <MenuItem onClick={() => handleNavigation('/register-patient-follow-up')}>Register Patient Follow Up</MenuItem>
              <MenuItem onClick={() => handleNavigation('/list-registrations')}>List Registrations</MenuItem>
              <MenuItem onClick={() => handleNavigation('/refund-patient-registration')}>Refund</MenuItem>
            </Menu>

            <Button color="inherit" onClick={handleOpenReportsMenu} sx={{ mx: 1 }}>
              Generate Reports
            </Button>
            <Menu anchorEl={reportsAnchorEl} open={Boolean(reportsAnchorEl)} onClose={handleCloseReportsMenu}>
              <MenuItem onClick={() => handleNavigation('/generate-reports-patient-regs')}>Patient Registrations</MenuItem>
              <MenuItem onClick={() => handleNavigation('/generate-reports-procedure-regs')}>Procedure</MenuItem>
            </Menu>
            
            <Button color="inherit" onClick={handleOpenProceduresMenu} sx={{ mx: 1 }}>
              Procedures
            </Button>
            <Menu anchorEl={proceduresAnchorEl} open={Boolean(proceduresAnchorEl)} onClose={handleCloseProceduresMenu}>
              <MenuItem onClick={() => handleNavigation('/register-procedure')}>Register Procedure</MenuItem>
              <MenuItem onClick={() => handleNavigation('/list-procedure-registrations')}>List Procedure Registrations</MenuItem>
              <MenuItem onClick={() => handleNavigation('/refund-procedure')}>Refund Procedure</MenuItem>
           
            </Menu>
            {userType === 'Admin' && (
              <>
                <Button color="inherit" onClick={() => handleNavigation('/manage-users')} sx={{ mx: 1 }}>
                  Manage Users
                </Button>
                <Button color="inherit" onClick={() => handleNavigation('/manage-departments')} sx={{ mx: 1 }}>
                  Manage Departments
                </Button>
                <Button color="inherit" onClick={() => handleNavigation('/manage-doctors')} sx={{ mx: 1 }}>
                  Manage Doctors
                </Button>
                <Button color="inherit" onClick={() => handleNavigation('/manage-procedures')} sx={{ mx: 1 }}>
                  Manage Procedures
                </Button>
              </>
            )}
            <Button color="inherit" onClick={handleLogout} sx={{ mx: 1 }}>
              Logout
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
        }}
      >
        {drawer}
      </Drawer>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(mobileMoreAnchorEl)}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={() => handleNavigation('/home')}>Home</MenuItem>
        <MenuItem onClick={handleOpenMenu}>Manage Patient Registrations</MenuItem>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
          <MenuItem onClick={() => handleNavigation('/register-patient')}>Register Patient</MenuItem>
          <MenuItem onClick={() => handleNavigation('/register-patient-follow-up')}>Register Patient Follow Up</MenuItem>
          <MenuItem onClick={() => handleNavigation('/list-registrations')}>List Registrations</MenuItem>
          <MenuItem onClick={() => handleNavigation('/refund-patient-registration')}>Refund</MenuItem>
        </Menu>

        <MenuItem onClick={handleOpenReportsMenu}>Generate Reports</MenuItem>
        <Menu anchorEl={reportsAnchorEl} open={Boolean(reportsAnchorEl)} onClose={handleCloseReportsMenu}>
           <MenuItem onClick={() => handleNavigation('/generate-reports-patient-regs')}>Patient Registrations</MenuItem>
           <MenuItem onClick={() => handleNavigation('/generate-reports-procedure-regs')}>Procedure</MenuItem>
        </Menu>
        
        <MenuItem onClick={handleOpenProceduresMenu}>Procedures</MenuItem>
        <Menu anchorEl={proceduresAnchorEl} open={Boolean(proceduresAnchorEl)} onClose={handleCloseProceduresMenu}>
          <MenuItem onClick={() => handleNavigation('/register-procedure')}>Register Procedure</MenuItem>
          <MenuItem onClick={() => handleNavigation('/list-procedure-registrations')}>List Procedure Registrations</MenuItem>
          <MenuItem onClick={() => handleNavigation('/refund-procedure')}>Refund</MenuItem>
        </Menu>
        {userType === 'Admin' && (
          <>
            <MenuItem onClick={() => handleNavigation('/manage-users')}>Manage Users</MenuItem>
            <MenuItem onClick={() => handleNavigation('/manage-departments')}>Manage Departments</MenuItem>
            <MenuItem onClick={() => handleNavigation('/manage-doctors')}>Manage Doctors</MenuItem>
            <MenuItem onClick={() => handleNavigation('/manage-procedures')}>Manage Procedures</MenuItem>
          </>
        )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <Container component="main" sx={{ my: 4, flexGrow: 1 }}>
        <Outlet />
      </Container>
      <Box component="footer" sx={{ p: 2, bgcolor: 'primary.main', color: 'white', textAlign: 'center', mt: 'auto' }}>
        <Typography variant="body1">© 2024 SATCDC Pune. All rights reserved.</Typography>
      </Box>
    </Box>
  );
}

export default Layout;
