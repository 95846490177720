import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, TextField, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, InputAdornment, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';

const passwordPlaceholder = '********';

function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({ id: '', username: '', password: '', user_type: 'User' });
  const [action, setAction] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [validationError, setValidationError] = useState('');
  const [search, setSearch] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://satcdc.pune.ajwebapps.com/api/user/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUsers(response.data.users);
      setFilteredUsers(response.data.users);
    } catch (error) {
      setError('Failed to fetch users');
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    const filtered = users.filter(user => 
      user.username.toLowerCase().includes(value) || 
      user.user_type.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
  };

  const handleOpen = (action, user = { id: '', username: '', password: '', user_type: 'User' }) => {
    setAction(action);
    setCurrentUser(user);
    setOpen(true);
    setValidationError('');
    setError('');
    setSuccess('');
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
    setSuccess('');
    setValidationError('');
  };

  const handleConfirmationOpen = () => {
    if ((action === 'Create' || action === 'Update') && (!currentUser.username || !currentUser.password || !currentUser.user_type)) {
      setValidationError('All fields are mandatory');
      return;
    }
    setValidationError('');
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleSubmit = async () => {
    handleConfirmationClose();
    try {
      if (action === 'Create') {
        await axios.post('https://satcdc.pune.ajwebapps.com/api/user/', currentUser, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('User created successfully');
      } else if (action === 'Update') {
        const updatedUser = { ...currentUser };
        if (updatedUser.password === passwordPlaceholder) {
          delete updatedUser.password; // This ensures the password remains unchanged
        }
        await axios.put(`https://satcdc.pune.ajwebapps.com/api/user/${currentUser.id}`, updatedUser, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('User updated successfully');
      } else if (action === 'Delete') {
        await axios.delete(`https://satcdc.pune.ajwebapps.com/api/user/${currentUser.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('User deleted successfully');
      }
      fetchUsers();
      handleClose();
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Action failed');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Manage Users
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <Button variant="contained" color="primary" onClick={() => handleOpen('Create')}>
        Create User
      </Button>
      <TextField
        placeholder="Search Users"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: 'primary.main', color: 'white' }}>
              <TableCell sx={{ color: 'white' }}>Username</TableCell>
              <TableCell sx={{ color: 'white' }}>User Type</TableCell>
              <TableCell sx={{ color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.user_type}</TableCell>
                <TableCell>
                  <Button variant="outlined" color="primary" onClick={() => handleOpen('Update', user)}>
                    Update
                  </Button>
                  <Button variant="outlined" color="primary" onClick={() => handleOpen('Delete', user)} sx={{ ml: 1 }}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{action} User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the information below to {action.toLowerCase()} the user.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="outlined"
            value={currentUser.username}
            onChange={(e) => setCurrentUser({ ...currentUser, username: e.target.value })}
            required
            disabled={action === 'Update' || action === 'Delete'}
          />
          {action !== 'Delete' && (
            <>
              <TextField
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                value={currentUser.password}
                onChange={(e) => setCurrentUser({ ...currentUser, password: e.target.value })}
                required
              />
              <FormControl component="fieldset" margin="dense" fullWidth>
                <FormLabel component="legend">User Type</FormLabel>
                <RadioGroup
                  row
                  value={currentUser.user_type}
                  onChange={(e) => setCurrentUser({ ...currentUser, user_type: e.target.value })}
                >
                  <FormControlLabel value="Admin" control={<Radio />} label="Admin" />
                  <FormControlLabel value="User" control={<Radio />} label="User" />
                </RadioGroup>
              </FormControl>
            </>
          )}
          {validationError && <Alert severity="error">{validationError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleConfirmationOpen} color="primary">{action}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
        <DialogTitle>Confirm {action}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {action.toLowerCase()} this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ManageUsers;
