import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, TextField, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';

function ManageDepartments() {
  const [departments, setDepartments] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState({ name: '', room_no: '' });
  const [action, setAction] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [validationError, setValidationError] = useState('');
  const [search, setSearch] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get('https://satcdc.pune.ajwebapps.com/api/department/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDepartments(response.data.data);
      setFilteredDepartments(response.data.data);
    } catch (error) {
      setError('Failed to fetch departments');
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    const filtered = departments.filter(department => 
      department.name.toLowerCase().includes(value) || 
      department.room_no.toLowerCase().includes(value)
    );
    setFilteredDepartments(filtered);
  };

  const handleOpen = (action, department = { name: '', room_no: '' }) => {
    setAction(action);
    setCurrentDepartment(department);
    setOpen(true);
    setValidationError('');
    setError('');
    setSuccess('');
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
    setSuccess('');
    setValidationError('');
  };

  const handleConfirmationOpen = () => {
    if (!currentDepartment.name || !currentDepartment.room_no) {
      setValidationError('All fields are mandatory');
      return;
    }
    setValidationError('');
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleSubmit = async () => {
    handleConfirmationClose();
    try {
      if (action === 'Create') {
        await axios.post('https://satcdc.pune.ajwebapps.com/api/department/', currentDepartment, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('Department created successfully');
      } else if (action === 'Update') {
        await axios.put(`https://satcdc.pune.ajwebapps.com/api/department/${currentDepartment.name}`, { room_no: currentDepartment.room_no }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('Department updated successfully');
      } else if (action === 'Delete') {
        await axios.delete(`https://satcdc.pune.ajwebapps.com/api/department/${currentDepartment.name}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSuccess('Department deleted successfully');
      }
      fetchDepartments();
      handleClose();
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Action failed');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Manage Departments
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <Button variant="contained" color="primary" onClick={() => handleOpen('Create')}>
        Create Department
      </Button>
      <TextField
        placeholder="Search Departments"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: 'primary.main', color: 'white' }}>
              <TableCell sx={{ color: 'white' }}>Department Name</TableCell>
              <TableCell sx={{ color: 'white' }}>Room Number</TableCell>
              <TableCell sx={{ color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDepartments.map((department) => (
              <TableRow key={department.name}>
                <TableCell>{department.name}</TableCell>
                <TableCell>{department.room_no}</TableCell>
                <TableCell>
                  <Button variant="outlined" color="primary" onClick={() => handleOpen('Update', department)}>
                    Update
                  </Button>
                  <Button variant="outlined" color="primary" onClick={() => handleOpen('Delete', department)} sx={{ ml: 1 }}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{action} Department</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the information below to {action.toLowerCase()} the department.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Department Name"
            type="text"
            fullWidth
            variant="outlined"
            value={currentDepartment.name}
            onChange={(e) => setCurrentDepartment({ ...currentDepartment, name: e.target.value })}
            disabled={action !== 'Create'}
            required
          />
          <TextField
            margin="dense"
            id="room_no"
            label="Room Number"
            type="text"
            fullWidth
            variant="outlined"
            value={currentDepartment.room_no}
            onChange={(e) => setCurrentDepartment({ ...currentDepartment, room_no: e.target.value })}
            required
          />
          {validationError && <Alert severity="error">{validationError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleConfirmationOpen} color="primary">{action}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
        <DialogTitle>Confirm {action}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {action.toLowerCase()} this department?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ManageDepartments;
