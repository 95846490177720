import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, Box, Alert } from '@mui/material';
import axios from 'axios';

function HomePage() {
  const [statistics, setStatistics] = useState({
    total_registrations: 0,
    total_collection: 0,
    total_followups: 0,
    monthly_registrations: 0,
    monthly_collection: 0,
    monthly_followups: 0,
    yearly_registrations: 0,
    yearly_collection: 0,
    yearly_followups: 0
  });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://satcdc.pune.ajwebapps.com/api/statistics/today', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setStatistics(response.data);
    } catch (error) {
      setError('Failed to fetch statistics');
    }
  };

  const renderCard = (title, value) => (
    <Grid item xs={12} sm={4}>
      <Card elevation={3} sx={{ borderRadius: 4, height: '100%' }}>
        <CardContent>
          <Typography variant="h6" component="h2" sx={{ color: 'primary.main' }}>
            {title}
          </Typography>
          <Typography variant="h3" sx={{ mt: 2, color: 'success.main', fontWeight: 'bold' }}>
            {value}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
        Dashboard
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Box sx={{ mt: 4, border: '2px solid #ccc', borderRadius: 2, p: 3 }}>
        <Typography variant="h5" component="h2" sx={{ mb: 2, color: 'primary.main', fontWeight: 'bold' }}>
          Today's Statistics
        </Typography>
        <Grid container spacing={4}>
          {renderCard('Total Registrations', statistics.total_registrations)}
          {renderCard('Total Collection (INR)', statistics.total_collection)}
          {renderCard('Total Follow-ups', statistics.total_followups)}
        </Grid>
      </Box>
      <Box sx={{ mt: 6, border: '2px solid #ccc', borderRadius: 2, p: 3 }}>
        <Typography variant="h5" component="h2" sx={{ mb: 2, color: 'primary.main', fontWeight: 'bold' }}>
          This Month
        </Typography>
        <Grid container spacing={4}>
          {renderCard('Monthly Registrations', statistics.monthly_registrations)}
          {renderCard('Monthly Collection (INR)', statistics.monthly_collection)}
          {renderCard('Monthly Follow-ups', statistics.monthly_followups)}
        </Grid>
      </Box>
      <Box sx={{ mt: 6, border: '2px solid #ccc', borderRadius: 2, p: 3 }}>
        <Typography variant="h5" component="h2" sx={{ mb: 2, color: 'primary.main', fontWeight: 'bold' }}>
          This Year
        </Typography>
        <Grid container spacing={4}>
          {renderCard('Yearly Registrations', statistics.yearly_registrations)}
          {renderCard('Yearly Collection (INR)', statistics.yearly_collection)}
          {renderCard('Yearly Follow-ups', statistics.yearly_followups)}
        </Grid>
      </Box>
    </Container>
  );
}

export default HomePage;
