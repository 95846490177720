import React, { useState } from 'react';
import {
  Container, Typography, TextField, Button, Grid, Alert, Paper, Box
} from '@mui/material';
import axios from 'axios';

function RefundProcedure() {
  const [procedureId, setProcedureId] = useState('');
  const [procedureDetails, setProcedureDetails] = useState(null);
  const [refundAmount, setRefundAmount] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const token = localStorage.getItem('token');

  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://satcdc.pune.ajwebapps.com/api/procedure_refund/${procedureId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setProcedureDetails(response.data.procedure_registration);
      setError('');
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Procedure not found');
      setProcedureDetails(null);
    }
  };

  const handleRefund = async () => {
    if (!refundAmount) {
      setError('Refund amount is required');
      return;
    }

    if (parseInt(refundAmount) > procedureDetails.total_amount) {
      setError('Refund amount cannot be greater than total amount paid');
      return;
    }

    if (procedureDetails.type === 'SAT' && procedureDetails.total_amount === 0) {
      setError('Refund not applicable for SAT type with total amount 0');
      return;
    }

    try {
      const response = await axios.post('https://satcdc.pune.ajwebapps.com/api/procedure_refund/', {
        procedure_registration_id: procedureDetails.id,
        refund_amount: parseInt(refundAmount)
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSuccess('Refund processed successfully');
      setError('');
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Refund failed');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Refund Procedure
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <Box component="form" noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="procedure_id"
              label="Procedure ID"
              variant="outlined"
              fullWidth
              required
              value={procedureId}
              onChange={(e) => setProcedureId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      {procedureDetails && (
        <Paper sx={{ mt: 4, p: 3 }}>
          <Typography variant="h6">Procedure Details</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography><strong>Procedure ID:</strong> {procedureDetails.id}</Typography>
            <Typography><strong>Registration No:</strong> {procedureDetails.registration_no}</Typography>
            <Typography><strong>Patient Name:</strong> {procedureDetails.patient_name}</Typography>
            <Typography><strong>Total Amount:</strong> {procedureDetails.total_amount}</Typography>
            <Typography><strong>Type:</strong> {procedureDetails.type}</Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              name="refund_amount"
              label="Refund Amount"
              variant="outlined"
              fullWidth
              required
              value={refundAmount}
              onChange={(e) => setRefundAmount(e.target.value)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleRefund}
            >
              Process Refund
            </Button>
          </Box>
        </Paper>
      )}
    </Container>
  );
}

export default RefundProcedure;
