import React, { useState, useEffect, useRef } from 'react';
import {
  Container, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Box, Grid, Alert, IconButton,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import axios from 'axios';
import { format } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import { pink } from '@mui/material/colors';

function RegisterProcedure() {
  const location = useLocation();
  const navigate = useNavigate();
  const [procedures, setProcedures] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [formData, setFormData] = useState({
    registration_no: location.state?.registeredPatient?.registration_no || '',
    patient_name: location.state?.registeredPatient?.name || '',
    title: location.state?.registeredPatient?.title || '',
    type: location.state?.registeredPatient?.type || '',
    referring_doctor: '',
    department_name: location.state?.registeredPatient?.department_name || '',
    patient_reg_date: location.state?.registeredPatient?.date || '',
    reg_valid_till_date: location.state?.registeredPatient?.valid_till || '',
    procedure_date: format(new Date(), 'dd-MM-yyyy'),
    age: location.state?.registeredPatient?.age || '',
    sex: location.state?.registeredPatient?.sex || '',
    procedure_sittings: []
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [receiptOpen, setReceiptOpen] = useState(false);
  const [receiptData, setReceiptData] = useState(null);
  const receiptRef = useRef();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (formData.department_name) {
      fetchProcedures(formData.department_name);
      if (formData.department_name === 'DENTAL') {
        fetchDoctors(formData.department_name);
      } else {
        setDoctors([]);
      }
    }
  }, [formData.department_name]);

  useEffect(() => {
    calculateTotalAmount();
  }, [formData.procedure_sittings]);

  const fetchProcedures = async (department_name) => {
    try {
      const response = await axios.get(`https://satcdc.pune.ajwebapps.com/api/procedure/${department_name}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setProcedures(response.data.data);
    } catch (error) {
      setError('Failed to fetch procedures');
    }
  };

  const fetchDoctors = async (department_name) => {
    try {
      const response = await axios.get(`https://satcdc.pune.ajwebapps.com/api/doctor/${department_name}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDoctors(response.data.data);
    } catch (error) {
      setError('Failed to fetch doctors');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleReceiptClose = () => {
    setReceiptOpen(false);
    resetForm();
    navigate('/register-procedure', { replace: true });
  };

  const handleProcedureChange = (index, event) => {
    const { name, value } = event.target;
    const newProcedureSittings = [...formData.procedure_sittings];
    newProcedureSittings[index][name] = value;

    if (name === 'procedure') {
      const selectedProcedure = procedures.find(p => p.procedure_name === value);
      if (selectedProcedure) {
        newProcedureSittings[index].total = selectedProcedure.fees_per_sitting * newProcedureSittings[index].sittings;
        newProcedureSittings[index].procedure_name = selectedProcedure.procedure_name;
      }
    }

    if (name === 'sittings') {
      const selectedProcedure = procedures.find(p => p.procedure_name === newProcedureSittings[index].procedure);
      if (selectedProcedure) {
        newProcedureSittings[index].total = selectedProcedure.fees_per_sitting * parseInt(value);
      }
    }

    setFormData({ ...formData, procedure_sittings: newProcedureSittings });
  };

  const handleAddRow = () => {
    setFormData({ ...formData, procedure_sittings: [...formData.procedure_sittings, { procedure: '', sittings: 1, total: 0 }] });
  };

  const handleRemoveRow = (index) => {
    const newProcedureSittings = formData.procedure_sittings.filter((_, i) => i !== index);
    setFormData({ ...formData, procedure_sittings: newProcedureSittings });
  };

  const calculateTotalAmount = () => {
    const total = formData.procedure_sittings.reduce((total, row) => total + row.total, 0);
    if (formData.type === 'SAT') {
      return 0;
    }
    return total;
  };

  const calculateSatDiscount = () => {
    if (formData.type === 'SAT') {
      return formData.procedure_sittings.reduce((total, row) => total + row.total, 0);
    }
    return 0;
  };

  const handleSubmit = () => {
    if (formData.procedure_sittings.length === 0) {
      setError('Please add at least one procedure/sitting.');
      return;
    }

    if (!formData.referring_doctor) {
      setError('Referring doctor is required.');
      return;
    }

    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationSubmit = async () => {
    // Calculate total amount and SAT discount
    const totalAmount = calculateTotalAmount();
    const satDiscount = calculateSatDiscount();
  
    // Update formData with total_amount and sat_discount
    const updatedFormData = { ...formData, total_amount: totalAmount, sat_discount: satDiscount };
  
    try {
      const response = await axios.post('https://satcdc.pune.ajwebapps.com/api/procedure_registration/', updatedFormData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const procedureId = response.data.procedure_registration.id;
      setSuccess('Procedure registered successfully');
      setReceiptData({ ...updatedFormData, procedure_id: `${procedureId}/${formData.registration_no}` });
      setReceiptOpen(true);
      setConfirmationOpen(false);
      resetForm();
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Registration failed');
    }
  };
  
  const handleSearchRegistration = async () => {
    try {
      const response = await axios.get(`https://satcdc.pune.ajwebapps.com/api/patient/${formData.registration_no}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const patient = response.data.patient;
      setFormData({
        ...formData,
        patient_name: patient.name,
        title: patient.title,
        type: patient.type,
        department_name: patient.department_name,
        patient_reg_date: patient.date,
        reg_valid_till_date: patient.valid_till,
        age: patient.age,
        sex: patient.sex
      });
      fetchProcedures(patient.department_name);
      if (patient.department_name === 'DENTAL') {
        fetchDoctors(patient.department_name);
      } else {
        setDoctors([]);
      }
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Patient not found');
    }
  };

  const handlePrint = () => {
    const printContents = receiptRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  

  const resetForm = () => {
    setFormData({
      registration_no: '',
      patient_name: '',
      referring_doctor: '',
      department_name: '',
      patient_reg_date: '',
      reg_valid_till_date: '',
      procedure_date: format(new Date(), 'dd-MM-yyyy'),
      age: '',
      sex: '',
      procedure_sittings: [],
      type: ''
    });
  };

  return (
    <Container>
      <style>
        {`
          @media print {
            body {
              -webkit-print-color-adjust: exact;
            }
            .no-print {
              display: none;
            }
            .print-container {
              page-break-inside: avoid;
              margin: 0;
              padding: 0;
            }
          }
        `}
      </style>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Register Procedure
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <Box component="form" noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              name="registration_no"
              label="Registration No"
              variant="outlined"
              fullWidth
              required
              value={formData.registration_no}
              onChange={handleInputChange}
              disabled={!!location.state?.registeredPatient}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 1 }}
              onClick={handleSearchRegistration}
              disabled={!!location.state?.registeredPatient}
            >
              Fetch Details
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="patient_name"
              label="Patient Name"
              variant="outlined"
              fullWidth
              required
              value={formData.patient_name}
              onChange={handleInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="type"
              label="Type (SAT/Non-SAT)"
              variant="outlined"
              fullWidth
              required
              value={formData.type}
              onChange={handleInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="age"
              label="Age"
              variant="outlined"
              fullWidth
              required
              value={formData.age}
              onChange={handleInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="sex"
              label="Sex"
              variant="outlined"
              fullWidth
              required
              value={formData.sex}
              onChange={handleInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="patient_reg_date"
              label="Registration Date"
              variant="outlined"
              fullWidth
              value={formData.patient_reg_date ? formData.patient_reg_date : ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="reg_valid_till_date"
              label="Valid Till Date"
              variant="outlined"
              fullWidth
              value={formData.reg_valid_till_date ? formData.reg_valid_till_date : ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="procedure_date"
              label="Procedure Date"
              variant="outlined"
              fullWidth
              required
              value={formData.procedure_date}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="department_name"
              label="Department"
              variant="outlined"
              fullWidth
              required
              value={formData.department_name}
              onChange={handleInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" required>
              {formData.department_name === 'DENTAL' ? (
                <Select
                  name="referring_doctor"
                  value={formData.referring_doctor}
                  onChange={handleInputChange}
                  label="Referring Doctor"
                >
                  {doctors.map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.name}>
                      {doctor.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <TextField
                  name="referring_doctor"
                  label="Referring Doctor"
                  fullWidth
                  required
                  value={formData.referring_doctor}
                  onChange={handleInputChange}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" gutterBottom>
              Procedure / Sittings
            </Typography>
            {formData.procedure_sittings.length === 0 && (
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Please add at least one procedure/sitting.
              </Typography>
            )}
            {formData.procedure_sittings.map((row, index) => (
              <Grid container spacing={2} key={index} alignItems="center">
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel id={`procedure-label-${index}`}>Procedure</InputLabel>
                    <Select
                      labelId={`procedure-label-${index}`}
                      id={`procedure-${index}`}
                      name="procedure"
                      value={row.procedure}
                      onChange={(event) => handleProcedureChange(index, event)}
                      label="Procedure"
                    >
                      {procedures.map((procedure) => (
                        <MenuItem key={procedure.id} value={procedure.procedure_name}>
                          {procedure.procedure_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="sittings"
                    label="Sittings"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    value={row.sittings}
                    onChange={(event) => handleProcedureChange(index, event)}
                    InputProps={{ inputProps: { min: 1, max: 10 } }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="total"
                    label="Total (INR)"
                    variant="outlined"
                    fullWidth
                    required
                    value={row.total}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    color="primary"
                    onClick={() => handleRemoveRow(index)}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddRow}
              sx={{ mt: 2 }}
            >
              Add Procedure / Sitting
            </Button>
          </Grid>
        </Grid>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Total Amount: {calculateTotalAmount()} INR
        </Typography>
        {formData.type === 'SAT' && (
          <Typography variant="h6" sx={{ mt: 2 }}>
            SAT Discount: {calculateSatDiscount()} INR
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
        >
          Register Procedure
        </Button>
      </Box>

      <Dialog open={confirmationOpen} onClose={handleConfirmationClose} maxWidth="md" fullWidth>
        <DialogTitle>Confirm Procedure Registration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to register the procedure with the following details?
          </DialogContentText>
          <Paper sx={{ padding: 2, marginTop: 2 }}>
            <Typography variant="h6">Procedure Details</Typography>
            <Divider sx={{ marginY: 1 }} />
            <Typography><strong>Registration No:</strong> {formData.registration_no}</Typography>
            <Typography><strong>Patient Name:</strong> {formData.patient_name}</Typography>
            <Typography><strong>Type:</strong> {formData.type}</Typography>
            <Typography><strong>Referring Doctor:</strong> {formData.referring_doctor}</Typography>
            <Typography><strong>Department:</strong> {formData.department_name}</Typography>
            <Typography><strong>Registration Date:</strong> {formData.patient_reg_date}</Typography>
            <Typography><strong>Valid Till Date:</strong> {formData.reg_valid_till_date}</Typography>
            <Typography><strong>Procedure Date:</strong> {formData.procedure_date}</Typography>
            {formData.procedure_sittings.map((row, index) => (
              <Box key={index} sx={{ marginY: 1 }}>
                <Typography><strong>Procedure:</strong> {row.procedure}</Typography>
                <Typography><strong>Sittings:</strong> {row.sittings}</Typography>
                <Typography><strong>Total (INR):</strong> {row.total}</Typography>
              </Box>
            ))}
            <Typography variant="h6" sx={{ mt: 2 }}>
              Total Amount: {calculateTotalAmount()} INR
            </Typography>
            {formData.type === 'SAT' && (
              <Typography variant="h6" sx={{ mt: 2 }}>
                SAT Discount: {calculateSatDiscount()} INR
              </Typography>
            )}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="primary">Cancel</Button>
          <Button onClick={handleConfirmationSubmit} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>

      {/*
      <Dialog open={receiptOpen} onClose={handleReceiptClose} maxWidth="md" fullWidth>
        <DialogContent ref={receiptRef}>
          <Paper className="print-container" sx={{ padding: 2, marginTop: 2, border: `1px solid ${pink[500]}`, borderRadius: 'px' }}>
            <Typography color={pink[500]} textAlign="center">
              Shri Anandpur Trust Charitable Medical Centre, Pune
            </Typography>
            <Typography textAlign="center" sx={{ mb: 3 }}>
              Receipt Generated at {format(new Date(), 'dd-MM-yyyy HH:mm')}
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography><strong>Procedure Number:</strong> {receiptData?.procedure_id}</Typography>
                <Typography><strong>Patient Name:</strong> {receiptData?.patient_name}</Typography>
                <Typography><strong>Type:</strong> {receiptData?.type}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Referring Doctor:</strong> Dr. {receiptData?.referring_doctor}</Typography>
                <Typography><strong>Department:</strong> {receiptData?.department_name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Date:</strong> {receiptData?.procedure_date}</Typography>
              </Grid>
            </Grid>
            <Typography color={pink[500]} gutterBottom>
              Procedure / Sittings
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Procedure</strong></TableCell>
                    <TableCell><strong>Sittings</strong></TableCell>
                    <TableCell><strong>Total (INR)</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {receiptData?.procedure_sittings.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.procedure}</TableCell>
                      <TableCell>{row.sittings}</TableCell>
                      <TableCell>{row.total}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ my: 3 }} />
            <Typography sx={{ mt: 2 }}>
              Total Amount: {receiptData?.total_amount} INR
            </Typography>
            {receiptData?.type === 'SAT' && (
              <Typography sx={{ mt: 2 }}>
                SAT Discount: {receiptData?.sat_discount} INR
              </Typography>
            )}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrint} color="primary">Print</Button>
          <Button onClick={handleReceiptClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      */}
      <Dialog open={receiptOpen} onClose={handleReceiptClose} maxWidth="md" fullWidth>
        <DialogContent ref={receiptRef}>
          <Box 
            className="print-container" 
            sx={{
              '@media print': {
                marginTop: '15%', // Enforce 15% margin at the top for print
                padding: 0, // Remove padding for better space utilization
                border: 'none', // Remove border in print for cleaner look
              },
              // Fallback for print preview issues
              paddingTop: '10%', // Ensure space at the top before print
            }}
          >
            {/* Details Section */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 0 }}> {/* Reduced margin-bottom */}
              <Box sx={{ flex: '1 1 33%', mb: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '10px' }}><strong>Date:</strong> {receiptData?.procedure_date}</Typography>
              </Box>
              <Box sx={{ flex: '1 1 33%', mb: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '10px' }}><strong>Procedure Number:</strong> {receiptData?.procedure_id}</Typography>
              </Box>
              <Box sx={{ flex: '1 1 33%', mb: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '10px' }}><strong>Patient Name:</strong> {receiptData?.title} {receiptData?.patient_name}</Typography>
              </Box>
              <Box sx={{ flex: '1 1 33%', mb: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '10px' }}><strong>Age/Gender:</strong> {receiptData?.age} / {receiptData?.sex}</Typography>
              </Box>
              <Box sx={{ flex: '1 1 33%', mb: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '10px' }}><strong>Type:</strong> {receiptData?.type}</Typography>
              </Box>
              <Box sx={{ flex: '1 1 33%', mb: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '10px' }}><strong>Referring Doctor:</strong> Dr. {receiptData?.referring_doctor}</Typography>
              </Box>
              <Box sx={{ flex: '1 1 33%', mb: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '10px' }}><strong>Department:</strong> {receiptData?.department_name}</Typography>
              </Box>
            </Box>

            {/* Table Section */}
            <TableContainer component={Paper} sx={{ mb: 2 }}> {/* Reduced margin-bottom */}
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: '1px', fontSize: '10px' }}><strong>Procedure</strong></TableCell>
                    <TableCell sx={{ padding: '1px', fontSize: '10px' }}><strong>Sittings</strong></TableCell>
                    <TableCell sx={{ padding: '1px', fontSize: '10px' }}><strong>Total (INR)</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {receiptData?.procedure_sittings.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '1px', fontSize: '10px' }}>{row.procedure}</TableCell>
                      <TableCell sx={{ padding: '1px', fontSize: '10px' }}>{row.sittings}</TableCell>
                      <TableCell sx={{ padding: '1px', fontSize: '10px' }}>{row.total}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell sx={{ padding: '1px', fontSize: '10px' }} colSpan={2}><strong>Total Amount</strong></TableCell>
                    <TableCell sx={{ padding: '1px', fontSize: '10px' }}>{receiptData?.total_amount} INR</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* Cashier Signature Section */}
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}> {/* Reduced margin-top */}
              <Typography variant="body2" sx={{ fontSize: '10px', textDecoration: 'underline' }}>
                Cashier: ______________
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrint} color="primary">Print</Button>
          <Button onClick={handleReceiptClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
}

export default RegisterProcedure;
