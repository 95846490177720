import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Grid, Alert, InputAdornment, IconButton, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { format } from 'date-fns';

function ListRegistrations() {
  const [registrations, setRegistrations] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredRegistrations, setFilteredRegistrations] = useState([]);
  const [error, setError] = useState('');
  const [department, setDepartment] = useState('ALL');
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchRegistrations();
  }, [department]);

  const fetchRegistrations = async () => {
    try {
      const today = format(new Date(), 'dd-MM-yyyy');
      const response = await axios.get('https://satcdc.pune.ajwebapps.com/api/patient/between_dates', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          start_date: today,
          end_date: today,
          department
        }
      });
      setRegistrations(response.data.registrations);
      setFilteredRegistrations(response.data.registrations);
    } catch (error) {
      setError('Failed to fetch registrations');
    }
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);

    const filtered = registrations.filter(reg =>
      reg.name.toLowerCase().includes(value.toLowerCase()) ||
      reg.department_name.toLowerCase().includes(value.toLowerCase()) ||
      reg.doctor_name.toLowerCase().includes(value.toLowerCase()) ||
      reg.registration_no.toString().includes(value)
    );
    setFilteredRegistrations(filtered);
  };

  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Today's Registrations
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      <FormControl component="fieldset" sx={{ mb: 3 }}>
        <FormLabel component="legend">Department</FormLabel>
        <RadioGroup row value={department} onChange={handleDepartmentChange}>
          <FormControlLabel value="DENTAL" control={<Radio />} label="DENTAL" />
          <FormControlLabel value="PHYSIO THERAPY" control={<Radio />} label="PHYSIO THERAPY" />
          <FormControlLabel value="POLY OPD" control={<Radio />} label="POLY OPD" />
          <FormControlLabel value="ALL" control={<Radio />} label="ALL" />
        </RadioGroup>
      </FormControl>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: 'primary.main', color: 'white' }}>
              <TableCell sx={{ color: 'white' }}>Registration No</TableCell>
              <TableCell sx={{ color: 'white' }}>Name</TableCell>
              <TableCell sx={{ color: 'white' }}>Age</TableCell>
              <TableCell sx={{ color: 'white' }}>Sex</TableCell>
              <TableCell sx={{ color: 'white' }}>Date</TableCell>
              <TableCell sx={{ color: 'white' }}>Valid Till</TableCell>
              <TableCell sx={{ color: 'white' }}>Department</TableCell>
              <TableCell sx={{ color: 'white' }}>Doctor</TableCell>
              <TableCell sx={{ color: 'white' }}>Type</TableCell>
              <TableCell sx={{ color: 'white' }}>Fees</TableCell>
              <TableCell sx={{ color: 'white' }}>SAT Discount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRegistrations.map((registration) => (
              <TableRow key={registration.registration_no}>
                <TableCell>{registration.registration_no}</TableCell>
                <TableCell>{registration.title} {registration.name}</TableCell>
                <TableCell>{registration.age}</TableCell>
                <TableCell>{registration.sex}</TableCell>
                <TableCell>{registration.date}</TableCell>
                <TableCell>{registration.valid_till}</TableCell>
                <TableCell>{registration.department_name}</TableCell>
                <TableCell>Dr. {registration.doctor_name}</TableCell>
                <TableCell>{registration.type}</TableCell>
                <TableCell>{registration.fees}</TableCell>
                <TableCell>{registration.sat_discount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default ListRegistrations;
